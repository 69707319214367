import React, { useEffect, useState } from "react";
import PageBar from "../../components/dashboard/page-bar/PageBar";
import ListAllAdminUsers from "../../components/admin-users/list-all-adminUsers/ListAdminUsers";
import axios from "axios";
import { deleteUser, getAllUser } from "../../components/apifile/ApiFiles";

const ListAllAdminUsersPage = () => {
  const [data, setData] = useState();

  const getUserData = async () => {
    try {
      const res = await getAllUser(10, 1)
      setData(res);
    } catch (error) { }
  };

  useEffect(() => {
    getUserData();
  }, []);

  const handleDelete = async (id) => {
    try {
      const res = await deleteUser(id)
      getUserData()
    } catch (error) {
      alert("Error");
    }
  };

  return (
    <>
      <div className="pageWrapper">
        <PageBar title=" Admin Users List" />
        <h3 className="page-title">
          Manage Admin Users <small>Admin Users</small>
        </h3>
        <ListAllAdminUsers data={data} handleDelete={handleDelete} />
      </div>
    </>
  );
};

export default ListAllAdminUsersPage;
